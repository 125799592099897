import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <header className="header">
      <nav className="nav">
        {/* Add navigation links if needed */}
        {/* Example: <Link to="/home">Home</Link> */}
      </nav>
    </header>
  );
}

export default Header;
