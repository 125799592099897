import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <p><strong>Privacy Policy for StrongAllAlong</strong></p>
  <p><strong>Last Updated: January 18th, 2024</strong></p>
  
  <p><strong>OUR PRIVACY STATEMENT</strong></p>

  <p>This Privacy Policy sets forth our policy with respect to information that can be associated with or which relates to a person and/or could be used to identify a person (“Personal Data”) that we collect from individuals that access or use our StrongAllAlong.coach website including all of our mobile application(s) or other Strong All Along products or services on any computer, mobile phone, tablet, console or other device. "Non-Personal Data" as used in this Privacy Policy is therefore any information that does not relate to a person and/or cannot be used to identify a person. When you interact with us through the Sites, we may also collect Non-Personal Data. The limitations and requirements in this Privacy Policy on our collection, use, disclosure, transfer and storage/retention of Personal Data do not apply to Non-Personal Data. Our primary goal in collecting your Personal Data and Non-Personal Data is to provide you with superior online services as well as information about our fitness programs, products and services.</p>

  <p>We take the privacy of your Personal Data very seriously. Because of that, we have created this Privacy Policy. This Privacy Policy describes how we collect and use your Personal and Non-Personal Data through a variety of digital means. By accessing or using our Sites, you consent to this Privacy Policy. Provisions of this Privacy Policy may be changed, modified, added or removed at any time and such changes will become effective immediately upon posting of a new policy on the Sites. Your continued use of our products and services constitutes your acceptance to this Privacy Policy and any updates thereto. This Privacy Policy is incorporated into, and is subject to, our Terms of Use.</p>
  
  <p><strong>WHAT INFORMATION ABOUT YOU DO WE COLLECT?</strong></p>
  
  <p>You may visit and browse the Sites without providing Personal Data. However, this may limit your ability to receive certain features, products or services from us.</p>

  <p><strong>Information you give us:</strong> We receive and store information you supply to us or permit us to access. This information may include, but is not limited to, your name, image, gender, date of birth, height, weight, lifestyle choices such as sleeping eating and exercise habits, email address, billing address, mailing address, billing information, telephone number, credit card number, credit card expiration date, shipping address, log-in name and password, social network username, calendar, location (GPS) information, Usage Information (as defined below), and electronic opt-in notifications that you authorize, including, but not limited to, third party opt-in options.</p>

  <p><strong>Information automatically collected:</strong> We automatically receive and store certain types of information regarding your interaction with, and use of, our products and services. For example, as is common practice, we use "cookies" and similar technologies to obtain and determine certain information when your browser accesses the Sites including, but not limited to, the Internet Protocol (IP) address used to connect your computer to the internet, email address, browser type and version, operating system and platform. For users of our mobile service(s), we automatically receive your location when you use the service. We may also receive other information from your mobile device, such as a device identifier. A device identifier is a small data file stored on or associated with your mobile device, which uniquely identifies your mobile device. A device identifier may be data stored in connection with the device hardware, data stored in connection with the device's operating system or other software, or data sent to the device by us. We or our third-party service providers may also use a variety of technologies that passively or automatically collect information about how the Sites is accessed and used (“Usage Information”), including your browser type, device type, operating system, application version, the pages served to you, the pages accessed by you, the time you browse, preceding page views, and your use of features or applications on the Sites. This statistical data helps us understand what is interesting and relevant to users of the Sites so we can best tailor our content. We may also use web beacons or pixel tags, which are transparent graphic images placed on a web page or in an email and indicate that a page or email has been viewed or tell your browser to get content from another server.</p>

  <p><strong>Information about you given to us by third parties:</strong> We may receive information about you from third parties that feature our programs, products, services, or promotional offers if you opt-in to receive information from us. You may also choose to participate in a third party application or social media site through which you allow us to collect (or the third party to share) information about you, including Usage Information and certain Personal and/or Non-Personal Data.</p>
      
  <p>We may also receive information about you from outside records from third parties, such as marketing related or demographic information to enhance our ability to tailor our content, products and services that we believe may be of interest to you. We may supplement the information we collect about you through the Sites with such information from third parties in order to enhance our ability to serve you, to tailor our content to you and/or to offer you opportunities to purchase products or services that we believe may be of interest to you. If we combine such data with information we collect through the Sites, such information is subject to this Privacy Policy unless we have disclosed otherwise.</p>

  <p><strong>Children’s Privacy</strong> – We do not knowingly collect Personal Data from children under the age of thirteen (13). If you are under the age of thirteen (13), please do not submit any Personal Data through the Sites. We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce our Privacy Policy by instructing their children never to provide Personal Data through the Sites without their permission. If you have reason to believe that a child under the age of 13 has provided Personal Data to us through the Sites, please email us at admin@strongallalong.coach, and we will delete that information from our records.</p>

  <p><strong>HOW WE USE PERSONAL DATA?</strong></p>

  <p>Except as otherwise provided in this Privacy Policy, we will not provide any of your Personal Data to any third parties without your specific consent. We may share Non-Personal Data, such as aggregate user statistics, Usage Information, and demographic information with third parties. The Personal Data we collect when you create a profile on the Sites will be used for identification and authentication purposes across our services, so you can use the same account details to log in from any location and on any device. The global single sign-on will allow our systems to identify you wherever you are in the world, meaning you will not need to register with us again if you are signing in from a different country. It is necessary to process your Personal Data in this way for the performance of a contract between you and Strong All Along.</p>

  <p>In addition, we will create a profile for you with the information needed to provide you with our products or services. The Personal Data we collect from the other sources listed above will be used in the format it is received, or, where appropriate, will be combined with Personal Data we obtain from other internal or external sources. We use and analyze this Personal Data where necessary for the purposes detailed below: Registering for a Strong All Along Instructor Training Course: we use relevant Personal Data (including your name, address email address, and payment details) in connection with your registration to attend a Strong All Along Instructor Training Course, and to notify you of the status of the training course and any details related thereto. It is necessary to process your Personal Data in this way for the performance of a contract between you and Strong All Along. Processing Your Order of Products/Services Through our Sites: we use relevant Personal Data (including your name, address email address, and payment details) to process and deliver your order, and to notify you of the status of your order. It is necessary to process your Personal Data in this way for the performance of a contract between you and Strong All Along.</p>

  <p><strong>Improving our Products, Programs and Services:</strong> we will use your Personal Data to build a profile on you in order to understand how you use our products, programs and services, to develop more interesting and relevant products, programs and services, to improve our Sites, as well as to personalize the products, programs and services we offer you. We process your Personal Data in this way as it is necessary for the purposes of our legitimate interests in providing better products and services for you and our other customers.</p>

  <p><strong>To Provide Customer Support:</strong> if you contact our Customer Service (or vice versa), we will use Personal Data to process your request and provide you with the best service possible. We will process your Personal Data in this way if it is necessary for the performance of a contract between you and Strong All Along or if it is required for us to comply with any legal obligations. If it is not necessary to process your Personal Data for either of these reasons we will process it as it is necessary for the purposes of our legitimate interests in ensuring we can provide the best service possible.</p>

  <p><strong>Conducting Analytics:</strong> we will use the Personal Data we hold about you (as well as pseudonymized or anonymized information generated from your Personal Data) to carry out analysis and research. We carry out all such analysis and research on the basis that it is necessary for the purposes of our legitimate interests in understanding our customers and ensuring that our products, programs and services meet the needs of our customers and licensees. We do this in order to (i) learn more about our customers and licensees and their preferences; (ii) to identify patterns and trends amongst our customers and licensees; (iii) to enhance user experience on our Sites; (iv) to provide information, content and offerings tailored to our customers' and licensees’ needs; (v) for general research and statistical purposes; (vi) to develop new products, programs and services; (vii) to monitor performance of our products, programs and services and/or improve our technology; (viii) to be able to send you personalized marketing messages; and (ix) to display online ads to you.</p>

  <p><strong>Analyzing our Business:</strong> we will use your Personal Data (including by anonymizing and aggregating it with other data) for sales, supply chain, anti-fraud, and financial analysis purposes, to determine how we are performing and where improvements can be made and where necessary for financial reporting purposes. This is necessary for the purposes of our legitimate interests in understanding how our business is performing, and considering how to improve our business performance.</p>

  <p><strong>Sharing Information:</strong> We may provide your Personal Data (i) to our subsidiaries, including Strong All Along Fitness - Europe B.V., which is located outside of the U.S.; (ii) to our service providers who assist us in conducting our business, including third parties involved in credit card processing, shipping, data management, email distribution, market research, information analysis, and promotions management; (iii) upon governmental request, in response to a court order, when required by law, to enforce our policies, or to protect our or others’ rights, property or safety; (iv) to prevent illegal uses of our products or services or violations of our Terms of Use, or to defend ourselves against third party claims; (v) to companies assisting us in fraud protection or investigations; and (vi) to a third party as part of our business assets in the event of a sale of all or a portion of our company.</p>

  <p><strong>Public Sharing</strong> – While on the Sites, you may have the opportunity to opt-in to receive information and/or marketing offers from someone else, or to otherwise consent to the sharing of your information with a third party, including social networking sites such as Facebook or Twitter. If you agree to have your Personal Data shared with a third party, your Personal Data will be disclosed to the third party subject to the privacy policy and business practices of that third party. You may also choose to share certain Personal Data on the Sites including, but not limited to, your activities on other platforms, such as social media networks like Facebook and Twitter. Please read the privacy policies of all such third party websites. Any Personal Data that share publicly may be used by us for promotional purposes. We may send you carefully selected offers from other businesses. When we do this, we do not give that business your name and address. If you do not want to receive such offers, please indicate that you are opting out of such notification in your user profile. Other than as set out above, you will receive notice when information about you might go to third parties, and you will have an opportunity to choose not to share the information.</p>
  
  <p><strong>INTEREST-BASED ADS</strong> We may partner with third-party service providers to serve ads on our behalf on non-affiliated third party websites. Some of those ads may be personalized, meaning that they are intended to be relevant to you based on information we or the online advertising network serving the ad collect about your online activities. For example, anonymous information collected across multiple websites that you visit over time may enable the ad network to predict your preferences and show you ads that are most likely to be of interest to you. Please note that we do not share any information that identifies you personally with the third party service providers who serve ads on our behalf.</p> To opt out of these types of ads, please visit the Network Advertising Initiative for information about how to opt-out of interest-based advertising by their members. See <a href="https://www.networkadvertising.org">https://www.networkadvertising.org</a> for general information about the NAI and <a href="https://www.networkadvertising.org/managing/opt_out.asp">https://www.networkadvertising.org/managing/opt_out.asp</a> for the opt-out page. You may also visit <a href="https://www.aboutads.info/consumers/">https://www.aboutads.info/consumers/</a> to learn about online behavioral advertising and how to opt-out from online behavioral ads served by some or all participating companies. Note that if you opt-out of these types of ads, you may still see our ads at other websites, but the ads will not be tailored using third party tracking technologies associated with our Sites.

  <p><strong>MOBILE MESSAGING TERMS</strong> When you provide us with your mobile phone number, you are expressly agreeing that we may send you text messages (including SMS) to that phone number. You also agree to pay the charges to your wireless bill or to have them deducted from your prepaid balance for this service. You acknowledge that you are thirteen years of age or older and you have the consent of the wireless account holder. We will send you up to ten messages per month. Message and data rates apply.</p>

  <p>Text STOP to the applicable code at any time to cancel and text HELP for help. You agree to receive a final text message confirming your opt-out. Consent is not required as a condition of purchasing any goods or services. You agree to notify us of any changes to your mobile number and update your information for us. Your carrier may prohibit or restrict certain mobile features and certain mobile features may be incompatible with your carrier or mobile device. Contact your carrier with questions regarding these issues.</p>

  <p>We reserve the right to modify these Mobile Messaging Terms by posting new versions on this website as well as the right to provide additional notice of significant changes. Your continued use of the mobile messaging service following such changes constitutes your acceptance of those changes.</p>

  <p><strong>HOW WE STORE PERSONAL DATA</strong> We may store Personal Data itself or such information may be stored by third parties to whom we have transferred it in accordance with this Privacy Policy. We take what we believe to be reasonable steps to protect the Personal Data we collect from loss, misuse, unauthorized use, access, inadvertent disclosure, alteration and destruction. However, no network, server, database or Internet or e-mail transmission is ever fully secure or error free. Therefore, you should take special care in deciding what information you send to us electronically. Please keep this in mind when disclosing any Personal Data.</p>

  <p><strong>YOUR RIGHTS, CHOICE, AND ACCESS</strong></p>

  <p>You may direct us not to share your Personal Data with third parties, not to use your Personal Data to provide you with information or offers, or not to send you communications by: (i) contacting us at <a href="mailto:admin@strongallalong.coach">admin@strongallalong.coach</a>; or (ii) following the removal instructions in the communication you receive. Please understand that if you are a licensed Strong All Along Instructor or if you have purchased certain products or services from us through the Sites, we must be able to communicate with you concerning your license, your licensed status, your benefits as a licensee, and any purchases you made through our Sites.</p>

  <p>You have the right to request access to the Personal Data we have of you. You can do this by contacting us at <a href="mailto:admin@strongallalong.coach">admin@strongallalong.coach</a>. In order to comply with your request, we may ask you to verify your identity. If you wish to modify, verify, correct, or update any of your Personal Data collected through the Sites, you may contact us at admin@strongallalong.coach. IF AT ANY POINT YOU WISH FOR US TO DELETE PERSONAL DATA ABOUT YOU, YOU CAN CONTACT US AT admin@strongallalong.coach. </p>

  <p>In accordance with our routine record keeping, we may delete certain records that contain Personal Data you have submitted through the Sites. We are under no obligation to store such Personal Data indefinitely and disclaim any liability arising out of, or related to, the destruction of such Personal Data.</p> 
  
  <p><strong>INTERNATIONAL PRIVACY LAWS</strong> If you are visiting the Sites from outside the United States, please be aware that you are sending information (including Personal Data) to the United States where our servers are located. That information may then be transferred within the United States or back out of the United States to other countries outside of your country of residence, depending on the type of information and how it is stored by us. These countries (including the United States) may not necessarily have data protection laws as comprehensive or protective as those in your country of residence; however, our collection, storage, and use of your Personal Data will at all times continue to be governed by this Privacy Policy.</p>

  <p><strong>CALIFORNIA DO NOT TRACK NOTICE</strong> We do not track our customers over time and across third party websites to provide targeted advertising and therefore do not respond to Do Not Track (DNT) signals. However, some third party websites do keep track of your browsing activities when they serve you content, which enables them to tailor what they present to you. If you are visiting such websites, Safari 5.1+, Internet Explorer 9+, Firefox 5+ and Chrome allow you to set the DNT signal on your browser so that third parties (particularly advertisers) know you do not want to be tracked.</p>

  <p><strong>WHAT IF I LINK TO A THIRD-PARTY WEBSITE FROM THE SITES?</strong> When you click links and/or banners that take you to third party websites, you will be subject to the third parties' privacy policies. We urge you to read and understand the privacy policies of such websites, as their practices may differ from ours.</p>

  <p><strong>HOW DO WE PROTECT PERSONAL DATA?</strong> We undertake reasonable measures, including using encryption technology, to keep your Personal Data safe. However, no electronic data transmission or storage of information can be guaranteed to be 100% secure. Please note that we cannot ensure or warrant the security of any information you transmit to us. It is important for you to protect against unauthorized access to your password and other personal information and to your computer. Be sure to sign off when finished using a shared computer.</p>

  <h2>Clarification on SMS and Email Communications</h2>

  <h3>SMS Communications through Twilio:</h3>
  <p>When you sign up for our services, we use the Twilio API to send you an SMS message. This message may include confirmation of registration, important updates about our services, or other information relevant to your use of our app.</p>
  <p><strong>Opt-Out Option:</strong> If at any time you wish to stop receiving SMS messages from us, you can opt-out by replying with a specific keyword like "STOP" to the received message. Upon receiving your opt-out request, we will cease sending you further SMS communications.</p>

  <h3>Email Communications through SendGrid:</h3>
  <p>Similarly, upon registration, you will receive an email through SendGrid. These emails serve to confirm your account, provide details about our services, or offer relevant updates and information.</p>
  <p><strong>Opting Out of Emails:</strong> You can unsubscribe from our email communications at any time. Each email we send will include an unsubscribe link at the bottom. By clicking this link, you will be removed from our email distribution lists and will no longer receive email communications from us.</p>

  <h3>Data Usage and Privacy:</h3>
  <p>We respect your privacy and ensure that your contact details are used solely for legitimate communication purposes related to our services. Your phone number and email address are stored securely and are not shared with third parties for marketing purposes without your explicit consent.</p>

  <h2>WhatsApp Business Communication</h2>
  
  <p>As part of our commitment to providing personalized and interactive service, we direct our clients to a private WhatsApp Business group. This platform allows us, and other group members, to connect and exchange messages. Here's how we handle data in this context:</p>

  <ul>
    <li><strong>Data Sharing and Privacy:</strong> When you join our WhatsApp Business group, you will be sharing your WhatsApp contact information with us and other members of the group. We value your privacy and remind all members to respect the confidentiality of information shared within the group.</li>
    <li><strong>Use of Information:</strong> The messages and content shared in the WhatsApp group are primarily for fostering community support, providing fitness tips, and addressing service-related queries. We advise against sharing sensitive personal information in the group setting.</li>
    <li><strong>Data Collection:</strong> We do not actively collect or store personal data shared in the WhatsApp group for any purposes outside the group’s intended use. However, please be aware that interactions within the group are subject to WhatsApp’s terms and privacy policy.</li>
    <li><strong>Opting Out:</strong> You have the option to leave the WhatsApp group at any time. If you choose to leave, your messages may remain in the group’s history, as per WhatsApp’s policy.</li>
  </ul>

  <p>We encourage all group members to maintain a respectful and supportive environment. For any concerns or questions about the use of WhatsApp for client communication, please contact us at admin@strongallalong.coach.</p>

  <h2>International Data Transfer Compliance</h2>

<p>As we operate across different international regions, including Europe, the United States, and Albania, we recognize the importance of complying with the various data protection laws applicable in these areas. Our approach to international data transfer is designed to ensure the secure and lawful processing of your Personal Data.</p>

<ul>
  <li><strong>Compliance with EU Data Protection Laws:</strong> For clients within the European Union, we adhere to the General Data Protection Regulation (GDPR) standards. This includes ensuring that any transfer of Personal Data outside the EU is carried out in compliance with GDPR, providing necessary safeguards.</li>
  <li><strong>Transfers to and from the United States:</strong> When transferring data to and from the United States, we take steps to ensure that such transfers are conducted in accordance with applicable privacy laws and international agreements to ensure the protection of your Personal Data.</li>
  <li><strong>Handling Data in Albania:</strong> For our clients in Albania, we comply with local data protection laws and ensure that international transfers are handled with the same level of security and legal compliance.</li>
  <li><strong>Safeguarding Data During Transfers:</strong> Regardless of where you are located, we implement appropriate safeguards to protect your Personal Data during its transfer. This includes the use of encryption and other forms of security measures.</li>
</ul>

<h3>Cookies from Our Site</h3>
  <p>The cookies set by our website are primarily for functionality, such as maintaining user sessions, storing preferences, and enabling essential website features. These are first-party cookies, which are crucial for the smooth operation of our site.</p>

  <h3>Cookies from Stripe for Payment Processing</h3>
  <p>We use Stripe as our payment processor, and Stripe sets third-party cookies on our site. These cookies, from domains like <a href="https://js.stripe.com">js.stripe.com</a> and <a href="https://m.stripe.network">m.stripe.network</a>, are used for secure payment transactions and fraud prevention. They are essential for processing payments securely on our website.</p>

  <p><strong>Consent and Control:</strong> We are committed to your privacy and ensure that all cookie usage is in line with legal requirements. Depending on your location and subject to applicable laws, you may be asked for consent for non-essential cookies. You can manage your cookie preferences in your web browser settings at any time.</p>

  <p>If you have any questions or require more information about our cookie usage, please feel free to contact us at <a href="mailto:admin@strongallalong.coach">admin@strongallalong.coach</a>.</p>

    
    </div>
  );
}

export default PrivacyPolicy;
