import React from 'react';

function Contact() {
  return (
    <div className="contact">
      <p>
        Contact Email: admin@strongallalong.coach
      </p>
    </div>
  );
}

export default Contact;
